<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="file-info" v-if="hasAuthorized">
            <el-collapse
                class="contract-collapse detail-form-collapse"
                v-model="collapseValue">
                <el-collapse-item
                    v-if="+group === 1"
                    name="file">
                    <template slot="title">合同信息</template>
                    <div class="file-box-father" :class="{'empty': !contractData.length}">
                        <div v-if="!contractData.length">暂无附件信息</div>
                        <div
                            class="file-box"
                            v-for="(contractItem) in contractData"
                            :key="contractItem.contractCode">
                            <el-row class="file-row">
                                <el-col :span="24"><span class="label"><span class="line"></span>合同编号：</span><span>{{contractItem.contractCode}}</span></el-col>
                                <el-col :span="24"><span class="label">合同名称：</span><span>{{contractItem.contractName}}</span></el-col>
                                <el-col :span="24"><span class="label">我方主体：</span><span>{{contractItem.applyContractUnit}}</span></el-col>
                                <el-col :span="10"><span class="label">本次需盖章文件：</span>
                                    <template v-if="contractItem.contractNeedStamp && contractItem.contractNeedStamp.length">
                                        <div
                                            class="file-item-box"
                                            v-for="(fileItem) in contractItem.contractNeedStamp"
                                            :key="fileItem.fileId">
                                            <div class="file-name-box">
                                                <span class="file-name" @click="viewDoc(fileItem)">{{fileItem.contractName}}</span>
                                            </div>
                                            <div class="down-btn">
                                                <lots-button
                                                    size="mini"
                                                    type="text"
                                                    @click="viewDoc(fileItem)">预览
                                                </lots-button>
                                                <lots-button
                                                    size="mini"
                                                    type="text"
                                                    @click="download(fileItem)">下载
                                                </lots-button>
                                            </div>
                                        </div>
                                    </template>
                                    <span v-else>(无)</span>
                                </el-col>
                                <el-col :span="14"><span class="label">原盖章文件：</span>
                                    <template v-if="contractItem.sourceNeedStamp && contractItem.sourceNeedStamp.length">
                                        <div
                                            class="file-item-box"
                                            v-for="(fileItem) in contractItem.sourceNeedStamp"
                                            :key="fileItem.fileId">
                                            <div class="file-name-box">
                                                <span class="file-name" @click="viewDoc(fileItem)">{{fileItem.contractName}}</span>
                                            </div>
                                            <div class="down-btn">
                                                <lots-button
                                                    size="mini"
                                                    type="text"
                                                    @click="viewDoc(fileItem)">预览
                                                </lots-button>
                                                <lots-button
                                                    size="mini"
                                                    type="text"
                                                    @click="download(fileItem)">下载
                                                </lots-button>
                                            </div>
                                        </div>
                                    </template>
                                    <span v-else>(无)</span>
                                </el-col>
                                <el-col :span="24">
                                    <el-row>
                                        <el-col :span="10">
                                            <span class="label">不需盖章参考文件：</span>
                                            <template v-if="contractItem.contractNotNeedStamp && contractItem.contractNotNeedStamp.length">
                                                <div
                                                    v-for="(fileItem) in contractItem.contractNotNeedStamp"
                                                    :key="fileItem.fileId"
                                                    class="file-item-box">
                                                    <div class="file-name-box">
                                                        <span class="file-name" @click="viewDoc(fileItem)">{{fileItem.contractName}}</span>
                                                    </div>
                                                    <div class="down-btn">
                                                        <lots-button
                                                            size="mini"
                                                            type="text"
                                                            @click="viewDoc(fileItem)">预览
                                                        </lots-button>
                                                        <lots-button
                                                            size="mini"
                                                            type="text"
                                                            @click="download(fileItem)">下载
                                                        </lots-button>
                                                    </div>
                                                </div>
                                            </template>
                                            <span v-else>(无)</span>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item
                    name="iflow">
                    <template slot="title">流程信息</template>
                    <div class="header-operation">
                        <lots-button
                            size="mini"
                            type="primary"
                            @click="submitMip"
                            :disabled="!!disabledFlag"
                            :loading="btnloading">
                            mip提交
                        </lots-button>
                        <lots-button style="margin-left: 10px;"
                            size="mini"
                            type="default"
                            @click="goDetail">
                            合同详情
                        </lots-button>
                    </div>
                    <iframe
                        class="ifr"
                        :src="mipUrl"
                        v-if="!btnloading" />
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="no-auth" v-else>抱歉，您暂时无权查看</div>
    </paper>
</template>

<script>
import paper from '@/components/core/Paper.vue';
import { iflowSubmitApi, approveProcess, submitByFwApi } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { mapGetters } from 'vuex';
import configurl from '@/config/user.env';
import lotsButton from '@/components/lots/lotsButton';
import Bus from '@/utils/bus.js';
import { contractWorkflowList, contractFilesView, contractFilesDownload } from '@mdm/api/contractManage/contractFootPageApi.js';
import { getByContractCodeApi } from '@mdm/api/contractManage/contractListApi.js';
import { queryContractEnclosureInfo } from '@mdm/api/contractManage/contractGroupListApi.js';
import {
    // getProcessTableInfoAndManualNodesApi, fwGetProcessTableInfoAndManualNodesApi, getAuditeNoteAllApi, getProcessInfoApi, getAuditeNoteApi,
    getAuthorByProcessIdApi
} from '@mdm/api/contractManage/mipIframeApi.js';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
// import { getItsmToken } from '@/api/newIT5000Api';
const env = configurl.env.toLowerCase();
const { MAGIC_NUMBER } = utils;
export default {
    components: {
        paper, lotsButton
    },
    data () {
        return {
            btnloading: false,
            mipUrl: '',
            disabledFlag: false,
            fdId: '',
            fdTemplateKey: '',
            contractCode: '',
            contractName: '',
            mipContractCode: '',
            contractCharacter: '',
            isFW: '',
            changeType: '',
            group: '',
            collapseValue: ['file', 'iflow'],
            contractData: [],
            authorized: false
        };
    },
    mounted() {
        this.disabledFlag = this.$route.query.disabled ? JSON.parse(this.$route.query.disabled) : false;
    },
    destroyed () {
        window.removeEventListener('message', this.messageFunc, false);
    },
    // 监听属性 类似于data概念
    computed: {
        ...mapGetters(['user', 'visitedViews', 'currentTenant', 'applicationCode', 'authority']),
        hasAuthorized() {
            let flag = false;
            const theMenu = Array.isArray(this.authority) && this.authority.length
                ? this.authority.find(item => item.resourceName && item.resourceName.includes('合同模块')) : null;
            if (theMenu?.subResources?.length) {
                flag = theMenu.subResources.some(item => item.resourceName
                    && (item.resourceName.includes('合同LCRM流程审批页') || item.resourceCode === 'RE7051576253501440' || item.frontUrl === '/mipIframe'));
            }
            return this.authorized || flag;
        }
    },
    // 监控data中的数据变化
    watch: {
        $route: {
            // eslint-disable-next-line complexity, max-lines-per-function
            handler(newVal, oldVal) {
                if (newVal.name === 'mipIframe' && newVal.query.contractCode) {
                    const {
                        fdId,
                        fdTemplateKey,
                        contractCode,
                        contractName,
                        mipContractCode,
                        contractCharacter,
                        changeType,
                        group
                    } = newVal.query;
                    this.fdTemplateKey = fdTemplateKey || '';
                    this.contractCode = contractCode;
                    this.collapseValue = ['iflow'];
                    if ((changeType && +changeType === 1) || fdTemplateKey === 'CRM_contract_price') { // 其他变更-价格变更
                        this.isFW = '';
                        this.changeType = '1';
                        this.fdTemplateKey = 'CRM_contract_price';
                        this.checkFdId(fdId, contractCode);
                        return false;
                    } else if (changeType && +changeType === 2) { // 其他变更-日期变更流程
                        this.isFW = '1';
                        this.changeType = '2';
                        this.fdTemplateKey = '';
                        this.checkFdId(fdId, contractCode);
                    } else if ((group && +group === 1) || fdTemplateKey === 'CRM_contract_group') { // 合同组流程
                        this.isFW = '';
                        this.group = '1';
                        this.fdTemplateKey = 'CRM_contract_group';
                        this.collapseValue = ['file', 'iflow'];
                        this.checkFdId(fdId, contractCode);
                    } else { // 非变更合同流程
                        this.group = '0';
                        if (!contractName || contractName === 'null' || !contractCharacter || contractCharacter === 'null'
                            || !mipContractCode || mipContractCode === 'null') {
                            // 重新请求数据
                            getByContractCodeApi({ contractCode }).then(res => {
                                if (res.code === '0' && res.data) {
                                    this.contractName = res.data.contractName;
                                    this.mipContractCode = String(res.data.mipContractCode);
                                    this.contractCharacter = String(res.data.contractCharacter);
                                    this.isFW = this.contractCharacter === '4' ? '' : '1';
                                    this.checkFdId(fdId, contractCode);
                                }
                            });
                        } else {
                            this.contractName = contractName;
                            this.mipContractCode = mipContractCode;
                            this.contractCharacter = contractCharacter;
                            this.isFW = contractCharacter === '4' ? '' : '1';
                            this.checkFdId(fdId, contractCode);
                        }
                    }
                    this.contractData = [];
                    if (+group === 1 || fdTemplateKey === 'CRM_contract_group') { // 合同信息
                        queryContractEnclosureInfo({ contractGroupCode: contractCode }).then(res => {
                            if (+res.code === 0) {
                                this.contractData = res.data;
                            }
                        });
                    }
                }
            },
            immediate: true
        }
    },
    // 方法集合
    methods: {
        checkFdId(fdId, contractCode) {
            if (fdId) {
                this.fdId = fdId;
                this.handleCreate();
                this.getIflowhandlers(this.fdId);
            } else {
                contractWorkflowList({ contractCode }).then(res => {
                    if (res.code === '0' && res.data && res.data.list && res.data.list.length) {
                        this.fdId = res.data.list[0].mipId;
                        this.handleCreate();
                        this.getIflowhandlers(this.fdId);
                    }
                });
            }
        },
        async getIflowhandlers(fdId) {
            if (env === 'ver') {
                this.authorized = true;
                return false;
            }
            const authorityMips = [];
            // const publicPrarms = { fdId, loginName: store.getters.user.userCode };
            let res = null;
            // if (this.isFW === '1') {
            //     res = await Promise.all([fwGetProcessTableInfoAndManualNodesApi(publicPrarms), getAuditeNoteApi(publicPrarms)]);
            // } else {
            //     res = await Promise.all([getProcessTableInfoAndManualNodesApi(publicPrarms), getAuditeNoteAllApi(publicPrarms), getProcessInfoApi(publicPrarms)]);
            // }
            // if (+res[0].code === 0 && res[0].data && res[0].data.processNodes && res[0].data.processNodes.length) {
            //     res[0].data.processNodes.forEach((item) => {
            //         item.handlerIds && authorityMips.push(...item.handlerIds.split(';'));
            //     });
            // }
            // if (this.isFW === '1' && +res[1].code === 0 && res[1].data && res[1].data.length) {
            //     res[1].data.forEach(item => {
            //         authorityMips.push(item.fdHandlerId);
            //     });
            // } else if (this.isFW !== '1' && +res[1].code === 0 && res[1].data && res[1].data.body && res[1].data.body.data && res[1].data.body.data.length) {
            //     res[1].data.body.data.forEach(item => {
            //         authorityMips.push(item.fdHandlerId);
            //     });
            // }
            // if (res[2] && +res[2].code === 0 && res[2].data && res[2].data.currNodes && res[2].data.currNodes.length) {
            //     res[2].data.currNodes.forEach(item => {
            //         if (item.handlers && item.handlers.length) {
            //             item.handlers.forEach(sub => {
            //                 authorityMips.push(sub.id);
            //             });
            //         }
            //     });
            // }
            res = await getAuthorByProcessIdApi({ fdProcessId: [fdId], loginName: store.getters.user.userCode });
            if (+res.code === 0 && res.data && res.data.length) {
                const theData = res.data.find(item => item.fdProcessId === fdId);
                this.authorized = theData.reader;
            }
            if (this.$route.query.isToOtherPersons) { // 传阅人也可以查看
                authorityMips.push(this.$route.query.isToOtherPersons);
            }
            this.authorized = this.authorized || authorityMips.some(item => item.includes(store.getters.user.userCode));
        },
        handleCreate() {
            const { userCode, userName } = this.user;
            // eslint-disable-next-line max-len
            this.mipUrl = `${configurl.iflowUrl}?fdId=${this.fdId}&fdTemplateKey=${this.fdTemplateKey}&userCode=${userCode}&userName=${userName}${this.isFW === '1' ? '&isFW=1' : ''}${this.isFW === '1' ? '&sourceSystem=LCRM' : ''}`;
            this.createMetaNode();
            window.addEventListener('message', this.messageFunc, false);
        },
        // eslint-disable-next-line max-lines-per-function, complexity
        messageFunc (event) {
            const { userCode } = this.user;
            if (event.data && event.data.data && event.data.success) {
                const {
                    fdId,
                    fdTemplateKey,
                    contractCode,
                    contractName,
                    mipContractCode,
                    contractCharacter,
                    isFW,
                    changeType,
                    group
                } = this;
                const { origin, pathname } = window.location;
                if (isFW !== '1') { // 非法务流程（预签合同、合同变更-价格变更、合同组）
                    const params = event.data.data || {};
                    params.tenantCode = this.currentTenant.tenantCode;
                    // params.currentApplicationCode = this.applicationCode;
                    params.currentApplicationCode = 'APP201904230047';
                    if (params.formParam) {
                        // params.formParam.formInstanceId = this.$route.query.id;
                        params.formParam.formInstanceId = contractCode;
                        const { processParam } = params;
                        let isToOtherPersons = '';
                        // eslint-disable-next-line max-depth
                        if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                            isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                        }
                        // eslint-disable-next-line max-depth
                        if (fdTemplateKey === 'CRM_contract_price' || changeType === '1') { // 其他变更（价格）
                            // eslint-disable-next-line max-len
                            params.formParam.fdUrl = `${origin}${pathname}#/mipIframe?fdId=${fdId}&fdTemplateKey=CRM_contract_price&contractCode=${contractCode}&changeType=1${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                        } else if (group === '1' || fdTemplateKey === 'CRM_contract_group') { // 合同组
                            // eslint-disable-next-line max-len
                            params.formParam.fdUrl = `${origin}${pathname}#/mipIframe?fdId=${fdId}&fdTemplateKey=CRM_contract_group&contractCode=${contractCode}&group=1${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                        } else {
                            // eslint-disable-next-line max-len
                            params.formParam.fdUrl = `${origin}${pathname}#/mipIframe?fdId=${fdId}&fdTemplateKey=${fdTemplateKey}&contractCode=${contractCode}&contractName=${contractName}&contractCharacter=${contractCharacter}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                        }
                    }
                    this.btnloading = true;
                    approveProcess(params).then(res => {
                        if (res && +res.code === 0) {
                            this.$message.success('提交成功');
                            this.$router.go(0);
                        }
                    }).finally(() => {
                        this.btnloading = false;
                    });
                } else if (+this.changeType === 2) { // 法务(合同日期变更)
                    const params = { contractCode, createUserCode: userCode };
                    params.url = `${origin}${pathname}#/mipIframe?fdId=${fdId}&contractCode=${contractCode}&changeType=${this.changeType}&isFW=1`;
                    params.flowInfo = event.data.data;
                    this.btnloading = true;
                    submitByFwApi(params)
                        .then(res => {
                            if (res && +res.code === 0) {
                                this.$message.success('提交成功');
                                // this.$router.go(0);
                                this.goDetail();
                            }
                        })
                        .finally(() => {
                            this.btnloading = false;
                        });
                } else { // 法务(新签、续签、补充、终止合同)
                    const params = { contractCode, contractName, mipContractCode, contractCharacter, createUserCode: userCode };
                    // eslint-disable-next-line max-len
                    params.url = `${origin}${pathname}#/mipIframe?fdId=${fdId}&contractCode=${contractCode}&contractName=${contractName}&mipContractCode=${mipContractCode}&contractCharacter=${contractCharacter}&isFW=1`;
                    params.flowInfo = event.data.data;
                    this.btnloading = true;
                    iflowSubmitApi(params)
                        .then(res => {
                            if (res && +res.code === 0) {
                                this.$message.success('提交成功');
                                // this.$router.go(0);
                            }
                        })
                        .finally(() => {
                            this.btnloading = false;
                        });
                }
            }
        },
        submitMip () {
            const content = window;
            content.frames && content.frames.length && content.frames[0]
                && content.frames[0].postMessage('mbpm_getApprovalData', this.mipUrl);
            content.frames && content.frames.length && content.frames[1]
                && content.frames[1].postMessage('mbpm_getApprovalData', this.mipUrl); // 解决接入了智能客服后，content.frames[0]被占用了的问题
        },
        createMetaNode() { // 2 生成  <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        },
        removeMetaNode() {
            const meta = document.getElementById('mipmeta');
            meta.parentNode.removeChild(meta);
        },
        SwitchHttpsProtocol() { // 1 判断进入页面的协议是否是http,如果是http将协议转换成https
            if (location.protocol === 'http:') {
                setTimeout(() => {
                    window.location.replace(
                        location.href.replace('http', 'https')
                    );
                }, 0);
            }
        },
        goDetail() {
            if (+this.group === 1 || this.fdTemplateKey === 'CRM_contract_group') {
                this.$router.push('/mod/mdm/contract-group-manage');
                setTimeout(() => {
                    Bus.$emit('viewContractGroupDetail', { contractGroupCode: this.contractCode });
                }, MAGIC_NUMBER.TWO_HUNDRED);
            } else {
                this.$router.push('/mod/mdm/contract-manage');
                setTimeout(() => {
                    Bus.$emit('viewContractDetail', { contractCode: this.contractCode, isOtherChange: Boolean(this.changeType) });
                }, MAGIC_NUMBER.TWO_HUNDRED);
            }
        },
        // 附件下载
        download(row) {
            const params = {
                userCode: store.getters.user.userCode,
                attachmentId: row.attachmentId,
                fileId: row.fileId,
                contractName: row.contractName
            };
            contractFilesDownload(params);
        },
        viewDoc(itemData) {
            const { fileId, id, attachmentId, contractName } = itemData;
            const params = {
                fileId,
                id,
                attachmentId,
                contractName,
                userCode: store.getters.user.userCode
            };
            contractFilesView(params).then((res) => {
                if (res && +res.code === 0) {
                    window.open(res.data, '附件预览');
                }
            });
        }
    }
};
</script>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);

.mip-iframe {
    overflow: auto;
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .file-info {
        .el-collapse-item__header {
            background-color: #edeff6;
            padding-left: 5px;
            font-size: 14px;
            font-weight: 600;
        }
        ::-webkit-scrollbar {
            width:8px;
            height:6px;
        }
        // 滚动块样式
        ::-webkit-scrollbar-thumb {
            background-color:@scrollbarThumbColor;
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color:@scrollbarThumbColorHover;
        }
    }
    .file-box-father {
        height: 550px;
        overflow-y: auto;
        &.empty {
            height: 20px;
        }
        .file-box {
            padding-left: 66px;
            .file-row {
                padding: 10px 0;
                border-bottom: 1px solid #dbdde4;
                color: #606266;
                .label {
                    position: relative;
                    .line {
                        display: inline-block;
                        width: 3px;
                        height: 12px;
                        background-color: #409eff;
                        position: absolute;
                        top: 3px;
                        left: -6px;
                    }
                }
                .file-item-box {
                    display: flex;
                    padding-left: 40px;
                    .file-name-box {
                        width: 350px;
                        .file-name {
                            color: #409EFF;
                            cursor: pointer;
                        }
                    }
                    .down-btn {
                        width: 90px;
                    }
                }
            }
        }
    }
    .header-operation {
        margin-top: 10px;
    }
}
.no-auth {
    font-size: 16px;
}
</style>
